import axios from 'axios';
import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import "./Hide.css"
import { listStudent as listStudent } from '../student/list/core/_requests'
import CustomSelect from '../../modules/custom-select/CustomSelect'
import FillForm from '../../modules/fill-form/FillForm';

const DashboardPage = () => {
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_API_URL
    const intl = useIntl()
    const [students, setStudents] = useState<StudentOption[]>([])
    const [studentid, setStudentId] = useState('')
    const [formStatuses, setFormStatuses] = useState([])
    const [activeFormTypeId, setActiveFormTypeId] = useState(null);
    const [formSummary, setFormSummary] = useState({ completedBefore: 0, completedNow: 0 });

    interface StudentOption {
        value: string;
        label: string;
    }

    const updateFormStatus = (formTypeId: any) => {
        setFormStatuses((prevStatuses:any) => prevStatuses.map((status:any) =>
            status.formTypeId === formTypeId ? { ...status, isFilled: true } : status
        ));

        setActiveFormTypeId(null);
    };

    function handleSelectStudent(data: any) {
        setStudentId(data?.value);
        setActiveFormTypeId(null);
    }

    function toggleAccordion(formTypeId: any) {
        setActiveFormTypeId(activeFormTypeId === formTypeId ? null : formTypeId);
    }

    useEffect(() => {
        listStudent("Id,Name,StudentNumber").then((res: any) => {
            setStudents(res.data.map((a: any) => { return { value: a?.id, label: a?.name + ' - ' + a?.studentNumber } }) || [])
        })
    }, [])

    useEffect(() => {
        if (studentid) {
            axios.get(`${API_URL}/Custom/FormCompletionStatus/${studentid}`)
                .then(response => {
                    setFormStatuses(response.data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }, [studentid])

    useEffect(() => {
        axios.get(`${API_URL}/Custom/FormCompletionSummary`)
            .then(response => {
                console.log(response.data);
                setFormSummary(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [formStatuses]);

    return (
        <>
            <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
            <div>
                <h3>Form Tamamlanma Durumları</h3>
                <p>2023 / 2024: {formSummary.completedBefore}</p>
                <p>2024 / 2025: {formSummary.completedNow}</p>
            </div>
            <div className='row'>
                <div className='mb-10 col-12'>
                    <label className='form-label fs-6 fw-bold'>
                        {intl.formatMessage({ id: 'Student' })}
                    </label>
                    <CustomSelect
                        options={students}
                        onChange={handleSelectStudent}
                        value={students.find(s => s.value === studentid) || undefined}
                        isSearchable={true}
                    />
                </div>
            </div>
            <div className='row'>
                {
                    formStatuses.map((formStatus: any) => (
                        <div key={formStatus.formTypeId}>
                            <div
                                className={`card mb-5 mb-xl-10 ${formStatus.isFilled ? 'bg-success' : 'bg-warning'}`}
                                onClick={() => toggleAccordion(formStatus.formTypeId)}
                            >
                                <div className='card-body'>
                                    <h5 className='card-title'>{formStatus.formTypeName}</h5>
                                    {
                                        formStatus.isFilled ?
                                            <p className='card-text'>Doldurulmuş</p> :
                                            <p className='card-text'>Doldurulmamış</p>
                                    }
                                </div>
                            </div>
                            {
                                activeFormTypeId === formStatus.formTypeId && (
                                    <div className='accordion-body'>
                                        <FillForm onFormSubmit={updateFormStatus} studentId={studentid} formTypeId={formStatus.formTypeId} closeForm={() => setActiveFormTypeId(null)} />
                                    </div>
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export { DashboardPage }